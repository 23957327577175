// import React, { useState, useEffect, useContext } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';
// import { useSelector } from 'react-redux';
// import Swal from 'sweetalert2';
// import { Context } from './Context';

// const Container = styled.div`
//   padding: 20px;
//   max-width: 600px;
//   margin: 0 auto;
//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const Title = styled.h2`
//   text-align: center;
//   margin-bottom: 20px;
//   color: rgba(0, 0, 255, 0.6);
// `;

// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
// `;

// const Label = styled.label`
//   margin: 10px 0 5px;
// `;

// const Input = styled.input`
//   padding: 10px;
//   font-size: 16px;
//   border-radius: 5px;
//   border: 1px solid #ddd;
//   margin-bottom: 15px;
// `;

// const Select = styled.select`
//   padding: 10px;
//   font-size: 16px;
//   border-radius: 5px;
//   border: 1px solid #ddd;
//   margin-bottom: 15px;
// `;

// const Button = styled.button`
//   padding: 12px;
//   font-size: 16px;
//   background-color: rgba(0, 0, 255, 0.6);
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   &:hover {
//     background-color: rgba(0, 0, 255, 0.7);
//   }
// `;

// const LessonPostPage = () => {
//   const [courses, setCourses] = useState([]);
//   const [selectedCourseId, setSelectedCourseId] = useState('');
//   const [lessonTitle, setLessonTitle] = useState('');
//   const [videoFile, setVideoFile] = useState(null);
//   const [lessonPosition, setLessonPosition] = useState('');
//   const authorInfo = useSelector((state) => state.authorInfo);
//   const {setAdminMenu} = useContext(Context)

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get(`https://elexdondigitalacademy.com/api/get_courses_by_author.php?authorId=${authorInfo.id}`);
//         if (response.data.success) {
//           setCourses(response.data.courses);
//         }
//       } catch (error) {
//         console.error('Error fetching courses:', error);
//       }
//     };

//     fetchCourses();
//   }, [authorInfo.id]);

//   const handleCourseChange = (e) => {
//     setSelectedCourseId(e.target.value);
//   };

//   const handleFileChange = (e) => {
//     setVideoFile(e.target.files[0]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const loadingAlert = Swal.fire({ text: "Uploading...",allowOutsideClick:false });
//     Swal.showLoading();

//     const formData = new FormData();
//     formData.append('title', lessonTitle);
//     formData.append('courseId', selectedCourseId);
//     formData.append('videoFile', videoFile);
//     formData.append('position', lessonPosition);

//     try {
//       const response = await axios.post('https://elexdondigitalacademy.com/api/create_lesson.php', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       console.log(response.data)

//       if (response.data.success) {
//         Swal.fire({ text: response.data.message });
//         setLessonTitle('');
//         setVideoFile(null);
//         setLessonPosition('');
//         setSelectedCourseId('');
//         setAdminMenu(0);
//       } else {
//         Swal.fire({ title: 'Error', text: response.data.error });
//       }
//     } catch (error) {
//       Swal.fire({ title: "Error posting lesson", text: error.message });
//       console.error('Error posting lesson:', error);
//     } finally {
//       loadingAlert.close();
//     }
//   };

//   return (
//     <Container>
//       <Title>Post a Lesson</Title>
//       <Form onSubmit={handleSubmit}>
//         <Label>Select Course:</Label>
//         <Select value={selectedCourseId} onChange={handleCourseChange} required>
//           <option value="">-- Select a course --</option>
//           {courses.map(course => (
//             <option key={course.id} value={course.id}>
//               {course.title}
//             </option>
//           ))}
//         </Select>

//         <Label>Lesson Position:</Label>
//         <Input
//           type="number"
//           value={lessonPosition}
//           onChange={(e) => setLessonPosition(e.target.value)}
//           placeholder='Enter lesson number (1, 2, etc.)'
//           required
//         />

//         <Label>Lesson Title:</Label>
//         <Input
//           type="text"
//           value={lessonTitle}
//           onChange={(e) => setLessonTitle(e.target.value.toUpperCase())}
//           required
//         />

//         <Label>Upload Video File (.mp4, .avi, .mov, .mkv):</Label>
//         <Input type="file" accept="video/*" onChange={handleFileChange} required />

//         <Button type="submit">Post Lesson</Button>
//       </Form>
//     </Container>
//   );
// };

// export default LessonPostPage;






import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Context } from './Context';

const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: rgba(0, 0, 255, 0.6);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin: 10px 0 5px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
`;

const Select = styled.select`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
`;

const Button = styled.button`
  padding: 12px;
  font-size: 16px;
  background-color: rgba(0, 0, 255, 0.6);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 255, 0.7);
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background: #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 15px;
`;

const Progress = styled.div`
  height: 100%;
  background: rgba(0, 0, 255, 0.6);
  transition: width 0.3s;
`;

const LessonPostPage = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [lessonTitle, setLessonTitle] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [lessonPosition, setLessonPosition] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const authorInfo = useSelector((state) => state.authorInfo);
  const { setAdminMenu } = useContext(Context);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`https://elexdondigitalacademy.com/api/get_courses_by_author.php?authorId=${authorInfo.id}`);
        if (response.data.success) {
          setCourses(response.data.courses);
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [authorInfo.id]);

  const handleCourseChange = (e) => {
    setSelectedCourseId(e.target.value);
  };

  const handleFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setUploadProgress(0);
  //   const loadingAlert = Swal.fire({ text: "Uploading...", allowOutsideClick: false });
  //   Swal.showLoading();

  //   const formData = new FormData();
  //   formData.append('title', lessonTitle);
  //   formData.append('courseId', selectedCourseId);
  //   formData.append('videoFile', videoFile);
  //   formData.append('position', lessonPosition);

  //   try {
  //     const response = await axios.post('https://elexdondigitalacademy.com/api/create_lesson.php', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //       onUploadProgress: (progressEvent) => {
  //         const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //         setUploadProgress(percentCompleted);
  //       },
  //     });

  //     console.log(response.data);
  //     if (response.data.success) {
  //       Swal.fire({ text: response.data.message });
  //       setLessonTitle('');
  //       setVideoFile(null);
  //       setLessonPosition('');
  //       setSelectedCourseId('');
  //       setUploadProgress(0);
  //       setAdminMenu(0);
  //     } else {
  //       Swal.fire({ title: 'Error', text: response.data.error });
  //     }
  //   } catch (error) {
  //     Swal.fire({ title: "Error posting lesson", text: error.message });
  //     console.error('Error posting lesson:', error);
  //   } finally {
  //     loadingAlert.close();
  //   }
  // };

  




  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadProgress(0);
  
    // Initialize SweetAlert with a progress bar
    Swal.fire({
      title: "Uploading...",
      html: `
        <div style="width: 100%; background: #ddd; border-radius: 5px; overflow: hidden; height: 10px;">
          <div id="swal-progress-bar" style="width: 0%; height: 100%; background: rgba(0, 0, 255, 0.6);"></div>
        </div>
        <p id="swal-progress-text">0%</p>
      `,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  
    const formData = new FormData();
    formData.append('title', lessonTitle);
    formData.append('courseId', selectedCourseId);
    formData.append('videoFile', videoFile);
    formData.append('position', lessonPosition);
  
    try {
      const response = await axios.post('https://elexdondigitalacademy.com/api/create_lesson.php', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
  
          // Update SweetAlert progress bar & percentage
          document.getElementById("swal-progress-bar").style.width = `${percentCompleted}%`;
          document.getElementById("swal-progress-text").innerText = `${percentCompleted}%`;
        },
      });
  
      if (response.data.success) {
        Swal.fire({ icon: "success", title: "Upload Complete!", text: response.data.message });
        setLessonTitle('');
        setVideoFile(null);
        setLessonPosition('');
        setSelectedCourseId('');
        setUploadProgress(0);
        setAdminMenu(0);
      } else {
        Swal.fire({ icon: "error", title: "Upload Failed", text: response.data.error });
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: "Error", text: error.message });
    }
  };
  
  
  
  return (
    <Container>
      <Title>Post a Lesson</Title>
      <Form onSubmit={handleSubmit}>
        <Label>Select Course:</Label>
        <Select value={selectedCourseId} onChange={handleCourseChange} required>
          <option value="">-- Select a course --</option>
          {courses.map(course => (
            <option key={course.id} value={course.id}>
              {course.title}
            </option>
          ))}
        </Select>

        <Label>Lesson Position:</Label>
        <Input
          type="number"
          value={lessonPosition}
          onChange={(e) => setLessonPosition(e.target.value)}
          placeholder='Enter lesson number (1, 2, etc.)'
          required
        />

        <Label>Lesson Title:</Label>
        <Input
          type="text"
          value={lessonTitle}
          onChange={(e) => setLessonTitle(e.target.value.toUpperCase())}
          required
        />

        <Label>Upload Video File (.mp4, .avi, .mov, .mkv):</Label>
        <Input type="file" accept="video/*" onChange={handleFileChange} required />

        <ProgressBar>
          <Progress style={{ width: `${uploadProgress}%` }} />
        </ProgressBar>

        <Button type="submit">Post Lesson</Button>
      </Form>
    </Container>
  );
};

export default LessonPostPage;

