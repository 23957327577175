import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './components/LandingPage';
import BlogDetail from './components/BlogDetail';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import AllCourses from './components/AllCourses';
import ContactForm from './components/ContactUs';
import SignUp from './components/SignUp';
import Login from './components/Login';
import EmailVerification from './components/EmailVerificationPage';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import PrivateRouteDashboard from './components/PrivateRouteDashboard';
import Dashboard from './components/Dashboard';
import CartPage from './components/CartPage';
import PrivateDeliveryDetail from './components/PrivateDeliveryDetail';
import DeliveryDetail from './components/DeliveryDetail';
import PrivateOrderSummary from './components/PrivateOrderSummary';
import OrderSummarypage from './components/OrderSummarypage';
import VerifyEmail from './components/VerifyEmail';
import SearchPage from './components/SearchPage';
import CourseDetailPage from './components/CourseDetailPage';
import AllCoursesDetailPage from './components/AllCoursesDetailPage';
import ManualScrollToTop from './components/ManualScrollToTop';
import Logo2 from "./Images/ELogo2.png"
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import RefundPolicy from './components/RefundPolicy';
import wp from "./Images/whatsapplogo.png"
import BecomeInstructorPage from './components/BecomeInstructorPage';
import InstructorRegistrationForm from './components/InstructorRegistrationForm';
import PrivateCourseDetailPage from './components/PrivateCourseDetailPage';
import BecomeAffiliatePage from './components/BecomeAffiliatePage';
import AffiliateRegistrationForm from './components/AffiliateRegistrationForm';
import SignUp2 from './components/SignUp2';
import SignUpForm from './components/signupTest';
import LoginForm from './components/LoginTest';
import { useEffect } from 'react';
import AuthorLogin from './components/AuthorLogin';
import AuthorSignUp from './components/AuthourSignUp';
import AuthorDashboard from './components/AuthorDashboard';
import AuthorVerifyEmail from './components/AuthorVerifyEmail';
import PrivateAuthorDasboard from './components/PrivateAuthorDasboard';
import AffiliateVerifyEmail from './components/AffiliateVerifyEmail';
import AffiliateLogin from './components/AffiliateLogin';
import AffiliateDashboard from './components/AffiliateDashBoard';
import PrivateAffiliateDasboard from './components/PrivateAffiliateDasboard';
import BTCWalletMonitor from './components/BitcoinQuery';
import TRXWalletMonitor from './components/TronWalletCheck';
import FooterBanner from './components/FooterBanner';
import ChristmasOverlay from './components/ChristmassWelcomeMessage';
import { useState } from 'react';
import ChristmassImg from './Images/ChristmassImg.png'
import AuthorForgotPassword from './components/AuthorForgotPassword';
import AuthorResetPassword from './components/AuthorResetPassword';


function App() {


  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    // Set a timer to hide the overlay after 2 seconds
    const timer = setTimeout(() => {
      setShowOverlay(false);
    }, 4000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);





  return (
    <BrowserRouter forceRefresh={true}>
      <ScrollToTop />
      {/* {showOverlay&&<ChristmasOverlay/>} */}
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/allcourses" element={<AllCourses />} />
        <Route path="/contactus" element={<ContactForm />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/emailverificationpage" element={<EmailVerification />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />

        <Route path="/resetpassword/:token" element={<ResetPassword />} />
        <Route path="/userdashboard" element={<PrivateRouteDashboard />}>
          <Route path="" element={<Dashboard />} />
        </Route>
        <Route path="/cartpage" element={<CartPage />} />
        <Route path="/deliverydetailpage" element={<PrivateDeliveryDetail />}>
          <Route path='' element={<DeliveryDetail />} />
        </Route>
        <Route path="/ordersummarypage" element={<PrivateOrderSummary />}>
          <Route path="" element={<OrderSummarypage />} />
        </Route>
        <Route path="/coursedetail/:id" element={<PrivateCourseDetailPage />}>
          <Route path='' element={<CourseDetailPage />} />
        </Route>
        <Route path="/verify/:token" element={<VerifyEmail />} />
        <Route path="/searchpage" element={<SearchPage />} />
        <Route path="/course/:id" element={<AllCoursesDetailPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/becomeinstructorpage" element={<BecomeInstructorPage />} />
        <Route path="instructorregistrationform" element={<InstructorRegistrationForm />} />
        <Route path="/becomeaffiliatepage" element={<BecomeAffiliatePage />} />
        <Route path="affiliateregistrationform" element={<AffiliateRegistrationForm />} />
        <Route path="/ref/:code" element={<SignUp2 />} />
        <Route path="signuptest" element={<SignUpForm />} />
        <Route path="/logintest" element={<LoginForm />} />
        <Route path="/authorlogin" element={<AuthorLogin/>}/>
        <Route path='/authorsignup' element={<AuthorSignUp/>}/>
        {/* <Route path="/authordashboard" element={<AuthorDashboard/>}/> */}

        <Route path="/authorverify/:token" element={<AuthorVerifyEmail />} />

        <Route path="/authordashboard" element={<PrivateAuthorDasboard/>}>
          <Route path='' element={<AuthorDashboard/>} />
        </Route>

        <Route path="/authorforgotpassword" element={<AuthorForgotPassword/>} />
        <Route path="/authorresetpassword/:token" element={<AuthorResetPassword/>} />

        <Route path='/affiliateverify/:token' element={<AffiliateVerifyEmail/>}/>
        <Route path="/affiliatelogin" element={<AffiliateLogin/>}/>

        <Route path="/affiliatedashboard" element={<PrivateAffiliateDasboard/>}>
          <Route path='' element={<AffiliateDashboard/>} />
        </Route>
        <Route path="/btc" element={<BTCWalletMonitor/>}/>
        <Route path="/trx" element={<TRXWalletMonitor/>}/>

      </Routes>
      {/* <img src={ChristmassImg} alt="Logo2" className='SideLogo2' /> */}
      <img src={Logo2} alt="Logo2" className='SideLogo' />
      <a><img src={wp} alt="logo" className="WhatsAppIcon" onClick={() => window.open("https://wa.me/2348185609702", "_blank")} /></a> 
      <ManualScrollToTop />
      {/* <FooterBanner/> */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
