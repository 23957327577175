import React, { useContext, useState } from 'react';
import '../CSS/ContactUs.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Context } from './Context';
import axios from 'axios';
import Swal from 'sweetalert2';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import Logo from '../Images/ELogo.png';
import { authorLogin } from '../Features/Slice';

const AuthorLogin = () => {
  const location = useLocation();
  const { AuthorLoginUrl } = useContext(Context);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'email' ? value.toLowerCase() : value
    });
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      const response = await axios.post(AuthorLoginUrl, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      console.log(response.data);
  
      if (response.data.success) {
        if (!response.data.author.emailVerified) {
          Swal.fire({
            icon: 'info',
            title: 'Email Not Verified',
            text: response.data.error,
            showConfirmButton: true,
            confirmButtonText: 'Ok'
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Login Successful',
            text: response.data.message,
            showConfirmButton: false,
            timer: 2000
          });
  
          const authorInfo = response.data.author;
          const authorToken = response.data.token;
          dispatch(authorLogin({ authorInfo, authorToken }));
          navigate('/authordashboard');
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: response.data.error,
        });
      }
    } catch (error) {
      console.error(error);
  
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response) {
        if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        } else if (typeof error.response.data === 'string') {
          errorMessage = error.response.data;
        } else {
          errorMessage = 'Unexpected error from server.';
        }
      } else if (error.request) {
        errorMessage = 'No response from server. Please check your internet connection.';
      } else {
        errorMessage = error.message;
      }
  
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: errorMessage,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='ContactFormWrap' key={location.pathname}>
      <div className='contact-form-container'>
        <h2>Author Login</h2>
        <img src={Logo} alt='Logo' style={{ position: 'relative', width: '70px' }} />
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='password'>Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              id='password'
              name='password'
              value={formData.password}
              onChange={handleChange}
              required
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              style={{ position: 'absolute', right: '10px', top: '65%', cursor: 'pointer', transform: 'translateY(-50%)' }}
            >
              {showPassword ? '🙈' : '👁️'}
            </span>
          </div>
          <button type='submit' disabled={isSubmitting}>
            {isSubmitting ? (
              <ClipLoader color={'#ffffff'} loading={isSubmitting} css={override} size={15} />
            ) : (
              'Login'
            )}
          </button>
        </form>
        <p style={{ marginTop: '10px', position: 'relative', cursor: 'pointer', color: 'blue' }} onClick={() => navigate('/authorforgotpassword')}>
          Author Forgot Password
        </p>
        <p style={{ marginTop: '10px', position: 'relative' }}>
          Don't have an author account? <span onClick={() => navigate('/authorsignup')} style={{ color: 'blue', cursor: 'pointer' }}>Sign Up as author</span>
        </p>
      </div>
    </div>
  );
};

export default AuthorLogin;
